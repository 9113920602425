<template>
  <div class="course-situation" :class="{ mh: !courseHandShow }">
    <tabs-tab defaultName="kcqk" :tabsList="tabsList" />
    <div class="kxqx-info-box">
      <div class="kxqx-title">
        <div class="title-text">{{ courseDetail.courseName }}</div>
        <van-tag
          color="#E02020"
          size="large"
          plain
          style="background-color: rgba(224, 32, 32, 0.1)"
          v-for="(item, index) in courseDetail.fitApplyPersonList"
          :key="'sydx' + index"
        >
          {{ fitApplyPersonMap[item] }}
        </van-tag>
        <van-tag
          color="#367BF4"
          size="large"
          plain
          style="background-color: rgba(54, 123, 244, 0.1)"
          v-show="
            !['', void 0, null, NaN].includes(
              courseTypeMap[courseDetail.courseType]
            )
          "
        >
          {{ courseTypeMap[courseDetail.courseType] }}
        </van-tag>
      </div>
      <div class="label-info-price-box">
        <div class="label-info-list">
          <div class="label-info-item">
            <div class="label-text">主理人：</div>
            <div class="value-text">{{ courseDetail.teacher }}</div>
          </div>
          <div class="label-info-item">
            <div class="label-text">课程周期：</div>
            <div class="value-text">
              {{ courseDetail.courseOpenStart }} ~
              {{ courseDetail.courseOpenEnd }}
            </div>
          </div>
          <div class="label-info-item">
            <div class="label-text">上课时间：</div>
            <div class="value-text">
              <div
                v-for="(item, index) in courseDetail.courseSettingList"
                :key="'kksj' + index"
              >
                {{ getDayWeekMonth(item) }}
              </div>
            </div>
          </div>
          <div class="label-info-item">
            <div class="label-text">课程地点：</div>
            <div class="value-text">{{ courseDetail.courseAddr }}</div>
          </div>
        </div>
        <div class="price-info">¥{{ courseDetail.price }}/整期</div>
      </div>
    </div>
    <div class="course-periods-list">
      <div
        class="course-periods-item"
        :class="{
          active: periodsActive === item.id,
          disabled: item.cancelFlag === 1,
        }"
        v-for="(item, index) in coursePeriodsList"
        :key="'kcqs' + index"
        :id="'kczqx' + item.id"
        @click="coursePeriodsOnClick(item, index)"
      >
        <div class="day-text">{{ item.day }}期</div>
        <div class="time-text">
          {{ item.startTime.slice(0, 5) }} ~ {{ item.endTime.slice(0, 5) }}
        </div>
      </div>
    </div>
    <div class="course-people-box">
      <div class="course-people-title">
        {{ coursePeopleDetail.coursePeriodDay }}
      </div>
      <div class="course-people-info">
        <div class="course-people-text">
          <div class="label-text">报名人数</div>
          <div class="value-text">
            <span class="value">{{ courseDetail.applyUserCount }}</span
            >人
          </div>
        </div>
        <div class="course-people-text">
          <div class="label-text">签到人数</div>
          <div class="value-text">
            <span class="value">{{ coursePeopleDetail.qdrs }}</span
            >人
          </div>
        </div>
        <div class="course-people-text">
          <div class="label-text">请假人数</div>
          <div class="value-text">
            <span class="value">{{ coursePeopleDetail.qjrs }}</span
            >人
          </div>
        </div>
      </div>
      <div class="course-people-list">
        <div
          class="course-people-item"
          v-for="(item, index) in coursePeopleList"
          :key="'ryqdqk' + index"
        >
          <div class="name-tag-box">
            <div class="name-text">{{ item.userName }}</div>
            <div class="qd-tag" :class="clockInStatusClassMap[item.checkin]">
              {{ clockInStatusMap[item.checkin] }}
            </div>
          </div>
          <van-icon
            name="phone-o"
            color="#367BF4"
            size="20"
            @click="callPhone(item.mobileNum)"
          />
          <div class="hand-round-dot" @click="handOnClick(item, index)"></div>
          <div
            class="mask-handle-box"
            v-show="item.handMaskShow"
            @click="maskNoShow(item, index)"
          >
            <div
              class="hand-btn"
              v-show="[1].includes(item.checkin)"
              @click="cancleQdOnClick(item, index)"
            >
              <img src="../img/icon_hand02.png" />取消签到
            </div>
            <div
              class="hand-btn"
              v-show="[0, 2].includes(item.checkin)"
              @click="handQdOnClick(item, index)"
            >
              <img src="../img/icon_hand04.png" />手动签到
            </div>
            <div
              class="hand-btn"
              v-show="[0, 1].includes(item.checkin)"
              @click="askLeaveOnClick(item, index)"
            >
              <img src="../img/icon_hand01.png" />请假
            </div>
            <div
              class="hand-btn"
              v-show="[2].includes(item.checkin)"
              @click="canLeaveOnClick(item, index)"
            >
              <img src="../img/icon_hand03.png" />销假
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button" v-show="courseHandShow">
      <div class="btn qxkc" @click="cancleCourseOnClick">取消课程</div>
      <div class="btn kcgq" @click="courseChangeDateOnClick">课程改期</div>
    </div>

    <van-popup
      v-model="changeDatePopupShow"
      :key="popupKey"
      round
      position="bottom"
      safe-area-inset-bottom
    >
      <van-datetime-picker
        v-model="courseChangeTime"
        type="datetime"
        title="改期时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="courseChangeOnOk"
        @cancel="changeDatePopupShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import tabsTab from "../components/tabsTab.vue";
import {
  getCourseTypeUrl,
  getCourseDetailUrl,
  getCouManScheListUrl,
  getCouManAtteListUrl,
  cancleCourseUrl,
  modifyCoursePeriodUrl,
  saveMyCourseSheetSessionKqUrl,
} from "../api.js";
import {
  fitApplyPersonMap,
  dayWeekMonthMap,
  clockInStatusMap,
  clockInStatusClassMap,
  getDayWeekMonth,
} from "../map.js";
import moment from "moment";
import { mapState } from "vuex";
import Vue from "vue";
import { Tag, Icon, Dialog } from "vant";

moment.locale("zh-cn");
Vue.use(Tag);
Vue.use(Icon);

export default {
  name: "courseSituation",
  components: { tabsTab },
  data() {
    return {
      fitApplyPersonMap,
      courseTypeMap: {},
      dayWeekMonthMap,
      clockInStatusMap,
      clockInStatusClassMap,
      tabsList: [
        {
          title: "课程情况",
          name: "kcqk",
          routeName: "nightSchoolCourseSituation",
        },
        {
          title: "报名情况",
          name: "bmqk",
          routeName: "nightSchoolSignUpSituation",
        },
      ],
      courseDetail: {
        id: void 0,
        courseName: "",
        fitApplyPersonList: [],
        courseType: void 0,
        teacher: "",
        courseOpenStart: "",
        courseOpenEnd: "",
        dayWeekMonth: void 0,
        courseSettingList: [],
        courseAddr: "",
        price: void 0,
        applyUserCount: void 0,
      },
      coursePeriodsList: [],
      periodsActive: "",
      coursePeopleDetail: {
        coursePeriodDay: "",
        day: "",
        startTime: "",
        endTime: "",
        qdrs: void 0,
        qjrs: void 0,
      },
      coursePeopleList: [],
      popupKey: "",
      changeDatePopupShow: false,
      courseChangeTime: null,
      minDate: null,
      maxDate: null,
    };
  },
  computed: {
    ...mapState(["userId"]),
    courseHandShow() {
      const couEndDateTime =
        this.coursePeopleDetail.day + " " + this.coursePeopleDetail.startTime;
      return new Date() < new Date(couEndDateTime);
    },
  },
  created() {
    Promise.all([
      this.$axios.get(getCourseTypeUrl, {}),
      this.$axios.get(getCourseDetailUrl, {
        params: { id: this.$route.query.id, userId: this.userId },
      }),
    ]).then(([courseTypeRes, kcxqRes]) => {
      if (courseTypeRes && courseTypeRes.code === 200) {
        courseTypeRes.data = Array.isArray(courseTypeRes.data)
          ? courseTypeRes.data
          : [];
        courseTypeRes.data.forEach((item) => {
          this.courseTypeMap[item.id] = item.courseType;
        });
      }
      if (kcxqRes && kcxqRes.code === 200) {
        kcxqRes.data = kcxqRes.data || {};
        kcxqRes.data.fitApplyPersonList =
          kcxqRes.data.fitApplyPerson.split(",");
        Object.assign(this.courseDetail, this.$_.cloneDeep(kcxqRes.data));
      }
    });
    this.getCourseQsList();
  },
  methods: {
    coursePeriodsOnClick(value, index) {
      if (value.cancelFlag === 1) return;
      this.periodsActive = value.id;
      this.getCoursePeopleInfo();
    },
    getCourseQsList(periodsId) {
      this.$axios
        .get(getCouManScheListUrl, {
          params: { courseId: this.$route.query.id },
        })
        .then((rqRes) => {
          if (rqRes && rqRes.code === 200) {
            rqRes.data = Array.isArray(rqRes.data) ? rqRes.data : [];
            let periodsActive = void 0;
            const gapTimestampList = [];
            rqRes.data.forEach((item) => {
              item.gapStartTimestamp =
                new Date(item.day + " " + item.startTime).getTime() -
                new Date().getTime();
              item.gapEndTimestamp =
                new Date(item.day + " " + item.endTime).getTime() -
                new Date().getTime();
              item.current =
                item.gapStartTimestamp <= 0 && item.gapEndTimestamp >= 0;
              if (item.current && item.cancelFlag !== 1) {
                periodsActive = item.id;
              }
              if (item.gapStartTimestamp > 0 && item.cancelFlag !== 1) {
                gapTimestampList.push(item.gapStartTimestamp);
              }
            });
            this.coursePeriodsList = rqRes.data;
            if (periodsId || periodsActive === 0) {
              this.periodsActive = periodsId;
            } else {
              if (periodsActive || periodsActive === 0) {
                this.periodsActive = periodsActive;
              } else {
                if (gapTimestampList.length > 0) {
                  const minTimestamp = Math.min(...gapTimestampList);
                  this.coursePeriodsList.some((item) => {
                    if (item.gapStartTimestamp === minTimestamp) {
                      this.periodsActive = item.id;
                      return true;
                    }
                  });
                } else {
                  this.periodsActive = this.coursePeriodsList[0].id;
                }
              }
            }
            this.getCoursePeopleInfo();
            this.$nextTick(() => {
              document
                .querySelector("#kczqx" + this.periodsActive)
                .scrollIntoView({ behavior: "smooth", inline: "center" });
            });
          }
        })
        .catch(() => {});
    },
    getCoursePeopleInfo() {
      this.$axios
        .get(getCouManAtteListUrl, {
          params: {
            courseId: this.$route.query.id,
            scheduleId: this.periodsActive,
          },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            res.data.forEach((item) => {
              item.checkin = ["", void 0, null, NaN].includes(item.checkin)
                ? 0
                : item.checkin;
              item.handMaskShow = false;
            });
            this.coursePeopleList = res.data;
            const value = this.coursePeriodsList.find(
              (item) => this.periodsActive === item.id
            );
            console.log("coursePeriodsList => ", this.coursePeriodsList);
            Object.assign(this.coursePeopleDetail, {
              coursePeriodDay:
                value.day +
                "期 " +
                value.startTime.slice(0, 5) +
                " ~ " +
                value.endTime.slice(0, 5),
              day: value.day,
              startTime: value.startTime,
              endTime: value.endTime,
              qdrs: this.coursePeopleList.filter((item) => item.checkin === 1)
                .length,
              qjrs: this.coursePeopleList.filter((item) => item.checkin === 2)
                .length,
            });
          }
        })
        .catch(() => {});
    },
    handOnClick(value, index) {
      this.$set(this.coursePeopleList[index], "handMaskShow", true);
    },
    maskNoShow(value, index) {
      this.$set(this.coursePeopleList[index], "handMaskShow", false);
    },
    callPhone(mobileNum) {
      const a = document.createElement("a");
      a.href = `tel:${mobileNum}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    cancleCourseOnClick() {
      Dialog.confirm({
        title: "操作提示",
        message: "取消后无法开启此期课程，是否确定取消？",
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          this.$axios
            .get(cancleCourseUrl, { params: { id: this.periodsActive } })
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCourseQsList();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    courseChangeDateOnClick() {
      const curdat =
        this.coursePeopleDetail.day + " " + this.coursePeopleDetail.startTime;
      this.popupKey = curdat;
      this.courseChangeTime = new Date(moment(curdat));
      this.minDate =
        new Date(curdat).getTime() <= new Date().getTime()
          ? new Date(curdat)
          : new Date();
      this.maxDate = new Date(moment(this.minDate).add(3, "M"));
      this.$nextTick(() => {
        this.changeDatePopupShow = true;
      });
    },
    courseChangeOnOk() {
      const sjc = moment(
        this.coursePeopleDetail.day + " " + this.coursePeopleDetail.endTime
      ).diff(
        this.coursePeopleDetail.day + " " + this.coursePeopleDetail.startTime,
        "seconds"
      );
      const day = moment(this.courseChangeTime).format("YYYY-MM-DD");
      const startTime = moment(this.courseChangeTime).format("HH:mm");
      const endTime = moment(day + " " + startTime)
        .add(sjc, "seconds")
        .format("HH:mm");
      const curdat =
        moment(this.courseChangeTime).format("YYYY-MM-DD期 HH:mm") +
        "~" +
        endTime;
      Dialog.confirm({
        title: "操作提示",
        message: `是否确定将【${this.courseDetail.courseName}】【${this.coursePeopleDetail.coursePeriodDay}】的课程时间改为【${curdat}】？`,
        overlayStyle: {
          backgroundColor: "transparent",
        },
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          this.changeDatePopupShow = false;
          const form = {
            id: this.periodsActive,
            day,
            startTime: startTime + ":00",
            endTime: endTime + ":00",
          };
          this.$axios
            .post(modifyCoursePeriodUrl, form)
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCourseQsList(this.periodsActive);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    cancleQdOnClick(value, index) {
      Dialog.confirm({
        title: "操作提示",
        message: `是否确认取消签到？`,
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          const qsVallue = this.coursePeriodsList.find(
            (item) => this.periodsActive === item.id
          );
          const form = {
            id: value.attendanceId || void 0,
            courseId: this.courseDetail.id,
            scheduleId: value.scheduleId || qsVallue.id || void 0,
            userId: value.userId,
            checkin: 0,
          };
          this.$axios
            .post(saveMyCourseSheetSessionKqUrl, form)
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCoursePeopleInfo();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handQdOnClick(value, index) {
      Dialog.confirm({
        title: "操作提示",
        message: `是否确认手动签到？`,
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          const qsVallue = this.coursePeriodsList.find(
            (item) => this.periodsActive === item.id
          );
          const form = {
            id: value.attendanceId || void 0,
            courseId: this.courseDetail.id,
            scheduleId: value.scheduleId || qsVallue.id || void 0,
            userId: value.userId,
            checkin: 1,
          };
          this.$axios
            .post(saveMyCourseSheetSessionKqUrl, form)
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCoursePeopleInfo();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    askLeaveOnClick(value, index) {
      Dialog.confirm({
        title: "操作提示",
        message: `是否确认为该居民请假？`,
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          const qsVallue = this.coursePeriodsList.find(
            (item) => this.periodsActive === item.id
          );
          const form = {
            id: value.attendanceId || void 0,
            courseId: this.courseDetail.id,
            scheduleId: value.scheduleId || qsVallue.id || void 0,
            userId: value.userId,
            checkin: 2,
          };
          this.$axios
            .post(saveMyCourseSheetSessionKqUrl, form)
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCoursePeopleInfo();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    canLeaveOnClick(value, index) {
      Dialog.confirm({
        title: "操作提示",
        message: `是否确认为该居民销假？`,
        confirmButtonColor: "#367BF4",
      })
        .then(() => {
          const qsVallue = this.coursePeriodsList.find(
            (item) => this.periodsActive === item.id
          );
          const form = {
            id: value.attendanceId || void 0,
            courseId: this.courseDetail.id,
            scheduleId: value.scheduleId || qsVallue.id || void 0,
            userId: value.userId,
            checkin: 0,
          };
          this.$axios
            .post(saveMyCourseSheetSessionKqUrl, form)
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getCoursePeopleInfo();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getDayWeekMonth,
  },
};
</script>

<style lang="less" scoped>
.course-situation {
  box-sizing: border-box;
  height: calc(100% - 100px);
  height: calc(100% - (100px + constant(safe-area-inset-bottom)));
  height: calc(100% - (100px + env(safe-area-inset-bottom)));
  background-color: #fafafa;
  overflow-y: auto;
  padding-bottom: 20px;
  overflow-x: hidden;
  &.mh {
    height: 100%;
    height: calc(100% - constant(safe-area-inset-bottom));
    height: calc(100% - env(safe-area-inset-bottom));
  }
}
.kxqx-info-box {
  box-sizing: border-box;
  margin: 20px 30px 0px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
  border-radius: 16px;
  padding: 34px 30px;
  .kxqx-title {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .title-text {
      box-sizing: border-box;
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
      color: rgba(0, 0, 0, 0.85);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .van-tag {
      flex-shrink: 0;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .label-info-price-box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .label-info-list {
      box-sizing: border-box;
      flex-grow: 1;
      .label-info-item {
        box-sizing: border-box;
        margin-top: 18px;
        font-size: 24px;
        line-height: 34px;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        align-items: flex-start;
        .label-text {
          flex-shrink: 0;
        }
      }
    }
    .price-info {
      flex-shrink: 0;
      font-size: 32px;
      color: #d9021b;
      font-weight: 700;
    }
  }
}
.course-periods-list {
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 30px;
  .course-periods-item {
    box-sizing: border-box;
    flex-shrink: 0;
    height: 104px;
    margin-right: 20px;
    background-color: #f6f7f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    line-height: 28px;
    color: rgba(0, 0, 0, 1);
    width: 204px;
    .time-text {
      margin-top: 10px;
    }
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      border: 1px solid #367bf4;
      background-color: rgba(54, 123, 244, 0.1);
      color: #367bf4;
    }
    &.disabled {
      color: #999999;
      pointer-events: none;
    }
  }
}
.course-people-box {
  box-sizing: border-box;
  margin: 20px 30px 0px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
  border-radius: 16px;
  overflow: hidden;
  .course-people-title {
    box-sizing: border-box;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    border-radius: 16px 16px 0px 0px;
    height: 56px;
    line-height: 26px;
    color: #ffffff;
    font-size: 24px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
  }
  .course-people-info {
    box-sizing: border-box;
    padding: 0px 26px;
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    .course-people-text {
      box-sizing: border-box;
      flex-shrink: 0;
      border: 1px solid #367bf4;
      width: calc(33.3% - 15px);
      height: 120px;
      border-radius: 16px;
      font-size: 24px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .value-text {
        margin-top: 15px;
        .value {
          font-size: 32px;
          line-height: 32px;
        }
      }
      &:nth-child(1) {
        border-color: #367bf4;
        background-color: rgba(54, 123, 244, 0.1);
        .value {
          color: #367bf4;
        }
      }
      &:nth-child(2) {
        border-color: #43c93e;
        background-color: rgba(67, 201, 62, 0.1);
        .value {
          color: #43c93e;
        }
      }
      &:nth-child(3) {
        border-color: #f59b23;
        background-color: rgba(245, 155, 35, 0.1);
        .value {
          color: #f59b23;
        }
      }
    }
  }
  .course-people-list {
    box-sizing: border-box;
    padding: 0px 26px 20px;
    .course-people-item {
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      margin-top: 20px;
      background-color: #f8fbff;
      border-radius: 16px;
      border: 2px solid #ccdeff;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0px 60px 0px 36px;
      .name-tag-box {
        flex-grow: 1;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .name-text {
          flex-shrink: 0;
          box-sizing: border-box;
          font-size: 26px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.85);
          width: 110px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .qd-tag {
          flex-shrink: 0;
          box-sizing: border-box;
          width: 122px;
          height: 48px;
          font-size: 24px;
          line-height: 24px;
          border-radius: 10px;
          border-width: 1px;
          border-style: solid;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          &.wqd {
            border-color: #999999;
            color: #999999;
          }
          &.yqd {
            border-color: #3ccb4b;
            color: #3ccb4b;
          }
          &.yqj {
            border-color: #fe9c1f;
            color: #fe9c1f;
          }
        }
      }
      .van-icon {
        flex-shrink: 0;
        margin-right: 10px;
      }
      .hand-round-dot {
        box-sizing: border-box;
        position: absolute;
        right: 28px;
        top: 28px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #9f9f9f;
        &::before {
          content: "";
          position: absolute;
          top: 8px;
          left: 0px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #9f9f9f;
        }
        &::after {
          content: "";
          position: absolute;
          top: 16px;
          left: 0px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #9f9f9f;
        }
      }
      .mask-handle-box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0px;
        top: 0px;
        border-radius: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .hand-btn {
          box-sizing: border-box;
          width: 192px;
          height: 60px;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 10px;
          font-size: 24px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.37);
          img {
            height: 30px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.footer-button {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  .btn {
    box-sizing: border-box;
    width: calc(50% - 10px);
    height: 66px;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0rpx 4px 8px 0px rgba(225, 225, 225, 0.5);
    &.qxkc {
      background: linear-gradient(
        180deg,
        #ededed 0%,
        #e0e0e0 61%,
        #d8d8d8 100%
      );
      color: #a8a8a8;
    }
    &.kcgq {
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
      color: #ffffff;
    }
  }
}
</style>
